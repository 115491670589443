function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { defineComponent } from 'vue';
import Sidebar from "./Sidebar.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import { ASSIST_ACTIVE_DB_CHANGED_EVENT, ASSIST_SET_DATABASE_EVENT } from "../../ko/components/assist/events";
import { AppType } from 'config/types';
import { CONFIG_REFRESHED_TOPIC } from 'config/events';
import { getLastKnownConfig } from 'config/hueConfig';
import huePubSub from 'utils/huePubSub';
import onHueLinkClick from 'utils/html/onHueLinkClick';
import I18n from "../../utils/i18n";
import { getFromLocalStorage, setInLocalStorage } from 'utils/storageUtils';
var APP_ICON_INDEX = {
  abfs: "<svg class=\"hi hi-fw\"><use href=\"#hi-adls\"></use></svg>",
  adls: "<svg class=\"hi hi-fw\"><use href=\"#hi-adls\"></use></svg>",
  dashboard: "<svg class=\"hi hi-fw\"><use href=\"#hi-dashboard\"></use></svg>",
  'data-browser': "<svg class=\"hi hi-fw\"><use href=\"#hi-data-browser\"></use></svg>",
  "default": "<i class=\"fa fa-fw fa-database\"></i>",
  'dist-cp': "<i class=\"fa fa-fw fa-files-o\"></i>",
  documents: "<svg class=\"hi hi-fw\"><use href=\"#hi-documents\"></use></svg>",
  editor: "<svg class=\"hi hi-fw\"><use href=\"#hi-editor\"></use></svg>",
  hbase: "<i class=\"fa fa-fw fa-th-large\"></i>",
  hdfs: "<i class=\"fa fa-fw fa-files-o\"></i>",
  hive: "<svg class=\"hi hi-fw\"><use href=\"#hi-hive\"></use></svg>",
  impala: "<svg class=\"hi hi-fw\"><use href=\"#hi-impala\"></use></svg>",
  importer: "<i class=\"fa fa-fw fa-cloud-upload\"></i>",
  indexes: "<i class=\"fa fa-fw fa-search-plus\"></i>",
  jar: "<svg class=\"hi hi-fw\"><use href=\"#hi-spark\"></use></svg>",
  java: "<i class=\"fa fa-fw fa-file-code-o\"></i>",
  'job-designer': "<svg class=\"hi hi-fw\"><use href=\"#hi-job-designer\"></use></svg>",
  kafka: "<i class=\"fa fa-fw fa-sitemap\"></i>",
  mapreduce: "<i class=\"fa fa-fw fa-file-archive-o\"></i>",
  markdown: "<svg class=\"hi hi-fw\"><use href=\"#hi-markdown\"></use></svg>",
  notebook: "<svg class=\"hi hi-fw\"><use href=\"#hi-file-notebook\"></use></svg>",
  oozie: "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie\"></use></svg>",
  'oozie-bundle': "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie-bundle\"></use></svg>",
  'oozie-coordinator': "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie-coordinator\"></use></svg>",
  'oozie-workflow': "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie-workflow\"></use></svg>",
  ofs: "<svg class=\"hi hi-fw\"><use href=\"#hi-ofs\"></use></svg>",
  gs: "<svg class=\"hi hi-fw\"><use href=\"#hi-gs\"></use></svg>",
  pig: "<svg class=\"hi hi-fw\"><use href=\"#hi-pig\"></use></svg>",
  py: "<svg class=\"hi hi-fw\"><use href=\"#hi-py\"></use></svg>",
  pyspark: "<svg class=\"hi hi-fw\"><use href=\"#hi-spark\"></use></svg>",
  queries: "<i class=\"fa fa-fw fa-tasks\"></i>",
  r: "<svg class=\"hi hi-fw\"><use href=\"#hi-r\"></use></svg>",
  report: "<i class=\"fa fa-fw fa-area-chart\"></i>",
  s3: "<i class=\"fa fa-fw fa-cubes\"></i>",
  scala: "<svg class=\"hi hi-fw\"><use href=\"#hi-scala\"></use></svg>",
  scheduler: "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie\"></use></svg>",
  security: "<i class=\"fa fa-fw fa-lock\"></i>",
  shell: "<i class=\"fa fa-fw fa-terminal\"></i>",
  solr: "<i class=\"fa fa-fw fa-search-plus\"></i>",
  spark2: "<svg class=\"hi hi-fw\"><use href=\"#hi-spark\"></use></svg>",
  spark: "<svg class=\"hi hi-fw\"><use href=\"#hi-spark\"></use></svg>",
  sqoop1: "<svg class=\"hi hi-fw\"><use href=\"#hi-sqoop\"></use></svg>",
  sqoop: "<svg class=\"hi hi-fw\"><use href=\"#hi-sqoop\"></use></svg>",
  support: "<svg class=\"hi hi-fw\"><use href=\"#hi-support\"></use></svg>",
  tables: "<i class=\"fa fa-fw fa-database\"></i>",
  text: "<i class=\"fa fa-fw fa-i-cursor\"></i>",
  warehouses: "<i class=\"altus-icon altus-adb-cluster\" style=\"margin: 0 1px 0 3px\"></i>",
  workflows: "<svg class=\"hi hi-fw\"><use href=\"#hi-oozie\"></use></svg>",
  yarn: "<i class=\"fa fa-fw fa-tasks\"></i>"
};
var getIconHtml = function getIconHtml(name) {
  return APP_ICON_INDEX[name] || APP_ICON_INDEX["default"];
};
var USER_DRAWER_CHILDREN = [];
if (window.USER_VIEW_EDIT_USER_ENABLED) {
  USER_DRAWER_CHILDREN.push({
    type: 'navigation',
    name: 'userEdit',
    displayName: I18n('My Profile'),
    handler: function handler(event) {
      return onHueLinkClick(event, '/useradmin/users/edit/' + window.LOGGED_USERNAME);
    }
  });
}
if (window.USER_IS_ADMIN || window.USER_IS_HUE_ADMIN) {
  USER_DRAWER_CHILDREN.push({
    type: 'navigation',
    name: 'userAdmin',
    displayName: I18n('Administer Users'),
    handler: function handler(event) {
      return onHueLinkClick(event, '/useradmin/users/');
    }
  });
  USER_DRAWER_CHILDREN.push({
    type: 'navigation',
    name: 'serverAdmin',
    displayName: I18n('Administer Server'),
    handler: function handler(event) {
      return onHueLinkClick(event, '/about/');
    }
  });
}
USER_DRAWER_CHILDREN.push({
  type: 'navigation',
  name: 'logOut',
  displayName: I18n('Log Out'),
  handler: function handler(event) {
    return onHueLinkClick(event, '/accounts/logout');
  }
});
var HELP_DRAWER_CHILDREN = [{
  type: 'navigation',
  name: 'documentation',
  displayName: I18n('Documentation'),
  handler: function handler(event) {
    return onHueLinkClick(event, 'https://docs.gethue.com', '_blank');
  }
}, {
  type: 'navigation',
  name: 'welcomeTour',
  displayName: I18n('Welcome Tour'),
  handler: function handler() {
    huePubSub.publish('show.welcome.tour');
  }
}, {
  type: 'navigation',
  name: 'gethue.com',
  displayName: 'gethue.com',
  handler: function handler(event) {
    return onHueLinkClick(event, 'https://gethue.com', '_blank');
  }
}];
export default defineComponent({
  name: 'HueSidebar',
  components: {
    Sidebar: Sidebar
  },
  provide: function provide() {
    var _this = this;
    return {
      selectedItemChanged: function selectedItemChanged(itemName) {
        if (itemName !== 'user' && itemName !== 'help' && itemName !== 'sidebar-collapse-btn' && _this.activeItemName !== itemName) {
          _this.activeItemName = itemName;
        }
      }
    };
  },
  setup: function setup() {
    var sideBarList = {
      userDrawerItem: {
        displayName: window.LOGGED_USERNAME || '',
        logoutLabel: I18n('Log Out'),
        logoutHandler: function logoutHandler(event) {
          return onHueLinkClick(event, '/accounts/logout');
        }
      },
      userDrawerChildren: USER_DRAWER_CHILDREN,
      subTracker: new SubscriptionTracker()
    };
    var helpDrawerList = {
      helpDrawerItem: {
        displayName: I18n('Help'),
        iconHtml: getIconHtml('support')
      },
      helpDrawerChildren: HELP_DRAWER_CHILDREN
    };
    if (window.ENABLE_HELP_MENU) {
      sideBarList = _objectSpread(_objectSpread({}, sideBarList), helpDrawerList);
    }
    return sideBarList;
  },
  data: function data() {
    return {
      activeItemName: '',
      drawerTopic: null,
      hueVersion: null,
      imgVersion: null,
      isCollapsed: getFromLocalStorage('hue.sidebar.collapse', true),
      sidebarItems: [],
      warehouseName: null
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    var config = getLastKnownConfig();
    if (config) {
      this.hueConfigUpdated(config);
    }
    this.subTracker.subscribe(CONFIG_REFRESHED_TOPIC, function (refreshedConfig) {
      _this2.hueConfigUpdated(refreshedConfig);
    });
    this.subTracker.subscribe(ASSIST_ACTIVE_DB_CHANGED_EVENT, function (details) {
      _this2.lastAssistDatabase = details;
    });
    this.subTracker.subscribe(ASSIST_SET_DATABASE_EVENT, function (details) {
      _this2.lastEditorDatabase = details;
    });
    this.subTracker.subscribe('set.current.app.name', this.currentAppChanged.bind(this));
    huePubSub.publish('get.current.app.name', this.currentAppChanged.bind(this));
  },
  unmounted: function unmounted() {
    this.subTracker.dispose();
  },
  methods: {
    onHeaderClick: function onHeaderClick(event) {
      onHueLinkClick(event, '/home');
    },
    toggleCollapsed: function toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
      setInLocalStorage('hue.sidebar.collapse', this.isCollapsed);
    },
    currentAppChanged: function currentAppChanged(appName) {
      var adaptedName;
      var params = new URLSearchParams(location.search);
      switch (appName) {
        case 'dashboard':
          adaptedName = params.get('engine') ? "".concat(appName, "-").concat(params.get('engine')) : appName;
          break;
        case 'editor':
          adaptedName = params.get('type') ? "".concat(appName, "-").concat(params.get('type')) : appName;
          break;
        case 'filebrowser':
          if (location.href.indexOf('=S3A') !== -1) {
            adaptedName = 's3';
          } else if (location.href.indexOf('=gs') !== -1) {
            adaptedName = 'gs';
          } else if (location.href.indexOf('=adl') !== -1) {
            adaptedName = 'adls';
          } else if (location.href.indexOf('=abfs') !== -1) {
            adaptedName = 'abfs';
          } else if (location.href.indexOf('=ofs') !== -1) {
            adaptedName = 'ofs';
          } else {
            adaptedName = 'hdfs';
          }
          break;
        case 'jobbrowser':
          adaptedName = 'yarn';
          break;
        case 'home':
          adaptedName = 'documents';
          break;
        case 'metastore':
          adaptedName = 'tables';
          break;
        case 'oozie_bundle':
          adaptedName = 'scheduler-oozie-bundle';
          break;
        case 'oozie_coordinator':
          adaptedName = 'scheduler-oozie-coordinator';
          break;
        case 'oozie_workflow':
          adaptedName = 'scheduler-oozie-workflow';
          break;
        case 'security_hive':
          adaptedName = 'security';
          break;
        case 'admin_wizard':
        case 'userAdmin':
        case 'userEdit':
        case 'useradmin_edituser':
        case 'useradmin_users':
          adaptedName = 'user';
          break;
        case 'hbase':
        case 'importer':
        case 'indexes':
        case 'kafka':
          break;
        default:
          console.warn('No sidebar alternative for app: ' + appName);
      }
      if (this.activeItemName !== adaptedName) {
        this.identifyActive(adaptedName || appName);
      }
    },
    identifyActive: function identifyActive(possibleItemName) {
      if (!possibleItemName) {
        return;
      }
      var findInside = function findInside(items) {
        var found = undefined;
        items.some(function (item) {
          if (item.children) {
            found = findInside(item.children);
          }
          var _ref = item,
            name = _ref.name;
          if (!found && (name === possibleItemName || name === "sdkapps-".concat(possibleItemName))) {
            found = name;
          }
          return found;
        });
        return found;
      };
      var foundName = findInside(this.sidebarItems);
      if (foundName) {
        this.activeItemName = foundName;
      }
    },
    hueConfigUpdated: function hueConfigUpdated(clusterConfig) {
      var _this3 = this;
      var items = [];
      this.hueVersion = (clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.hue_version) || null;
      this.imgVersion = (clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.img_version) || null;
      this.warehouseName = (clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.vw_name) || null;
      if (clusterConfig && clusterConfig.app_config) {
        var favourite = clusterConfig.main_button_action;
        var appsItems = [];
        var appConfig = clusterConfig.app_config;
        [AppType.editor, AppType.dashboard, AppType.scheduler, AppType.sdkapps].forEach(function (appName) {
          var config = appConfig[appName];
          if (window.CUSTOM_DASHBOARD_URL && appName === 'dashboard') {
            appsItems.push({
              type: 'navigation',
              name: 'dashboard',
              displayName: I18n('Dashboard'),
              iconHtml: getIconHtml('dashboard'),
              handler: function handler() {
                window.open(window.CUSTOM_DASHBOARD_URL, '_blank');
              }
            });
            return;
          }
          if (config && config.interpreters.length) {
            if (config.interpreters.length === 1 && config.name !== 'other') {
              appsItems.push({
                type: 'navigation',
                name: "".concat(appName, "-").concat(config.name),
                displayName: config.displayName,
                iconHtml: getIconHtml(config.name),
                url: config.page,
                handler: function handler(event) {
                  return onHueLinkClick(event, config.page);
                }
              });
            } else {
              var subApps = [];
              config.interpreters.forEach(function (interpreter) {
                var interpreterItem = {
                  type: 'navigation',
                  name: "".concat(appName, "-").concat(interpreter.type),
                  displayName: interpreter.displayName,
                  url: interpreter.page,
                  handler: function handler(event) {
                    return onHueLinkClick(event, interpreter.page);
                  }
                };
                if (favourite && favourite.page === interpreter.page) {
                  // Put the favourite on top
                  subApps.unshift(interpreterItem);
                } else {
                  subApps.push(interpreterItem);
                }
              });
              if (appName === 'editor' && window.SHOW_ADD_MORE_EDITORS) {
                subApps.push({
                  type: 'spacer'
                });
                var url = window.HAS_CONNECTORS ? '/desktop/connectors' : 'https://docs.gethue.com/administrator/configuration/connectors/';
                subApps.push({
                  type: 'navigation',
                  name: 'editor-AddMoreInterpreters',
                  displayName: I18n('Edit list...'),
                  url: url,
                  handler: function handler(event) {
                    return onHueLinkClick(event, url);
                  }
                });
              }
              var mainUrl = subApps[0].url || config.page || '/';
              appsItems.push({
                type: 'accordion',
                name: config.name,
                displayName: config.displayName,
                url: mainUrl,
                handler: function handler(event) {
                  return onHueLinkClick(event, mainUrl);
                },
                iconHtml: getIconHtml(config.name),
                children: subApps
              });
            }
          }
        });
        items.push.apply(items, appsItems);
        var browserItems = [];
        if (appConfig.home) {
          var url = appConfig.home.page || '/';
          browserItems.push({
            type: 'navigation',
            name: 'documents',
            displayName: appConfig.home.buttonName,
            url: url,
            handler: function handler(event) {
              return onHueLinkClick(event, url);
            },
            iconHtml: getIconHtml('documents')
          });
        }
        if (appConfig.browser && appConfig.browser.interpreters) {
          // Replace old file browser entries with the new storage browser if the feature flag is enabled.
          var browserInterpreters = [];
          if (clusterConfig.hue_config.enable_new_storage_browser) {
            var firstFileBrowserFound = false;
            appConfig.browser.interpreters.forEach(function (browser) {
              var isFileBrowser = /\/filebrowser/.test(browser.page);
              if (isFileBrowser) {
                if (!firstFileBrowserFound) {
                  browserInterpreters.push({
                    buttonName: 'Browse',
                    //TODO: Get i18n here
                    displayName: 'Storage Browser',
                    //by default the first filesystem's url in the config is used.
                    page: browser.page,
                    tooltip: 'Storage Browser',
                    type: 'data-browser'
                  });
                }
                firstFileBrowserFound = true;
              } else {
                browserInterpreters.push(browser);
              }
            });
          } else {
            browserInterpreters = appConfig.browser.interpreters;
          }
          browserInterpreters.forEach(function (browser) {
            if (browser.type === 'tables') {
              browserItems.push({
                type: 'navigation',
                name: browser.type,
                displayName: browser.displayName,
                url: browser.page,
                handler: function handler(event) {
                  var details = _this3.lastEditorDatabase || _this3.lastAssistDatabase;
                  var url = browser.page;
                  if (details) {
                    url += "/".concat(details.name || details.database, "?connector_id=").concat(details.connector.id);
                  }
                  onHueLinkClick(event, url);
                },
                iconHtml: getIconHtml(browser.type)
              });
            } else {
              browserItems.push({
                type: 'navigation',
                name: browser.type,
                displayName: browser.displayName,
                url: browser.page,
                handler: function handler(event) {
                  return onHueLinkClick(event, browser.page);
                },
                iconHtml: getIconHtml(browser.type)
              });
            }
          });
        }
        if (browserItems.length) {
          if (items.length) {
            items.push({
              type: 'spacer'
            });
          }
          items.push.apply(items, browserItems);
        }
        this.sidebarItems = items;
        if (!this.activeItemName) {
          huePubSub.publish('get.current.app.name', this.currentAppChanged.bind(this));
        }
      }
    }
  }
});