function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { defineComponent } from 'vue';
import AccordionItem from "./AccordionItem.vue";
import BaseNavigationItem from "./BaseNavigationItem.vue";
import HelpDrawerContent from "./HelpDrawerContent.vue";
import NavigationItem from "./NavigationItem.vue";
import SidebarBody from "./SidebarBody.vue";
import SidebarDrawer from "./SidebarDrawer.vue";
import UserDrawerContent from "./UserDrawerContent.vue";
export default defineComponent({
  name: 'Sidebar',
  components: {
    AccordionItem: AccordionItem,
    HelpDrawerContent: HelpDrawerContent,
    UserDrawerContent: UserDrawerContent,
    SidebarDrawer: SidebarDrawer,
    NavigationItem: NavigationItem,
    BaseNavigationItem: BaseNavigationItem,
    SidebarBody: SidebarBody
  },
  props: {
    sidebarItems: {
      type: Object,
      required: true
    },
    useDrawerForUser: {
      type: Boolean,
      "default": true
    },
    userDrawerItem: {
      type: Object,
      "default": null
    },
    userDrawerChildren: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    useDrawerForHelp: {
      type: Boolean,
      "default": true
    },
    helpDrawerItem: {
      type: Object,
      "default": null
    },
    helpDrawerChildren: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    hueVersion: {
      type: String,
      "default": null
    },
    imgVersion: {
      type: String,
      "default": null
    },
    warehouseName: {
      type: String,
      "default": null
    },
    activeItemName: {
      type: String,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      required: true,
      "default": true
    },
    drawerTopic: {
      type: Object,
      "default": null
    }
  },
  emits: ['toggle-collapsed', 'header-click'],
  data: function data(props) {
    return {
      drawerTopicInternal: props.drawerTopic
    };
  },
  computed: {
    footerVersionTitle: function footerVersionTitle() {
      if (this.imgVersion && this.hueVersion) {
        return "".concat(this.imgVersion, " - ").concat(this.hueVersion);
      }
      return null;
    },
    helpItem: function helpItem() {
      var _this = this;
      if (this.helpDrawerItem != null) {
        var sharedProps = {
          name: 'help',
          displayName: this.helpDrawerItem.displayName,
          iconHtml: this.helpDrawerItem.iconHtml
        };
        if (this.useDrawerForHelp) {
          return _objectSpread(_objectSpread({
            type: 'navigation'
          }, sharedProps), {}, {
            handler: function handler() {
              _this.drawerTopicInternal = 'help';
            }
          });
        }
        return _objectSpread(_objectSpread({
          type: 'accordion'
        }, sharedProps), {}, {
          children: this.helpDrawerChildren
        });
      }
      return null;
    },
    userItem: function userItem() {
      var _this2 = this;
      if (!this.userDrawerItem) {
        return null;
      }
      var sharedProps = {
        name: 'user',
        displayName: this.userDrawerItem.displayName,
        iconHtml: "<div class=\"sidebar-user-icon server-position-pointer-welcome-tour\" role=\"img\">".concat(this.userDrawerItem.displayName[0].toUpperCase(), "</div>")
      };
      if (this.useDrawerForUser) {
        return _objectSpread(_objectSpread({
          type: 'navigation'
        }, sharedProps), {}, {
          handler: function handler() {
            _this2.drawerTopicInternal = 'user';
          }
        });
      }
      return _objectSpread(_objectSpread({
        type: 'accordion'
      }, sharedProps), {}, {
        children: this.userDrawerChildren
      });
    }
  },
  watch: {
    drawerTopic: function drawerTopic(value) {
      this.drawerTopicInternal = value;
    }
  },
  methods: {
    closeDrawer: function closeDrawer(topic) {
      if (this.drawerTopicInternal === topic) {
        this.drawerTopicInternal = null;
      }
    }
  }
});