import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_BaseNavigationItem = _resolveComponent("BaseNavigationItem");
  var _component_SpacerItem = _resolveComponent("SpacerItem");
  return _ctx.item.type === 'navigation' ? (_openBlock(), _createBlock(_component_BaseNavigationItem, {
    key: _ctx.item.name,
    "css-classes": _ctx.active ? 'sidebar-sidebar-item-accordion-sub-item sidebar-sidebar-item-accordion-sub-item-active' : 'sidebar-sidebar-item-accordion-sub-item',
    disabled: _ctx.disabled,
    item: _ctx.item
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.item.displayName), 1 /* TEXT */)];
    }),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["css-classes", "disabled", "item"])) : _ctx.item.type === 'spacer' ? (_openBlock(), _createBlock(_component_SpacerItem, {
    key: 1
  })) : _createCommentVNode("v-if", true);
}